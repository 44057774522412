@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/effects';

.content {
  width: auto;
  min-height: 12rem;
  margin-bottom: 10rem;

  text-align: left;

  border-radius: 2rem;
  background: #fff;

  .pageHeader {
    padding: 1.6rem 0;
  }

  .row {
    flex-wrap: wrap;

    width: 100%;

    display: flex;
    flex-direction: row;
  }

  .column {
    width: 100%;
  }

  .header {
    font-weight: 600;
    line-height: 1.94;

    margin-top: 2.8rem;
  }

  .mobileOnly {
    display: none !important;
  }

  .fileLinkButton {
    font-weight: 600;
    line-height: 2.33;

    cursor: pointer;
    letter-spacing: -.43px;

    color: $primaryColor;

    &:hover {
      color: $primaryColorLight;
    }
  }

  .resultsTable {
    overflow: hidden;

    margin-bottom: 5rem;

    .phoneNumberCell {
      white-space: nowrap;
    }
  }

  @media (min-width: $tablet) {
    padding: 2rem 3rem;

    box-shadow: $baseBoxShadow;

    .column {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  @media (min-width: $laptopXS) {
    .mobileOnly {
      display: block !important;
    }

    .resultsTable {
      margin-right: 50%;
    }
  }

  @media (min-width: $laptopM) {
    padding: 4.8rem 7.8rem;
  }

  @media (min-width: $laptopXL) {
    width: $laptopM;
  }
}
