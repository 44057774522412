@import '../../../styles/colors';
@import '../../../styles/spacing';
@import '../../../styles/font';
@import '../../../styles/effects';

.container {
  .label {
    font-size: $fontMedium;
    line-height: $lineHeight;

    margin-bottom: $marginSmall;

    display: flex;
    flex-direction: row;
  }

  .error {
    font-size: $fontMedium;
    line-height: $lineHeight;

    padding: 0 $paddingSmall;

    color: $textColorLight;
    border: solid 1px $errorColor;
    border-radius: 4px;
    background: $errorBgColor;
    display: flex;
    flex-direction: column;
  }
}
