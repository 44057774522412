@import '../../../styles/breakpoints';
@import '../../../styles/effects';
@import '../../../styles/font';
@import '../../../styles/sizes';

.modalContent {
  width: 100vw;
  max-width: $laptopM;
  height: calc(100vh - calc(100vh - 100%));
  max-height: 1000px;
  padding-bottom: 1.7rem;

  border-radius: 0;

  @media (min-width: $tablet) {
    width: calc(100vw - 6.4rem);
    height: calc(100vh - 19rem);
    padding-bottom: 3.4rem;

    border-radius: 2rem;
  }

  @media (min-width: $laptopM) {
    width: calc(100vw - 34rem);
  }
}

.header {
  font-size: 1.8rem;
  line-height: 1.72;

  display: flex;
  align-items: center;

  padding: 2.4rem 1.7rem;

  text-decoration: none;

  .appName {
    margin-left: 2.4rem;
  }

  img {
    width: $mediumIconSize;
    height: $mediumIconSize;
  }

  @media (min-width: $tablet) {
    padding: 3.4rem;

    .appName {
      font-size: 2.4rem;

      margin-left: 3.2rem;
    }

    img {
      width: $largeIconSize;
      height: $largeIconSize;
    }
  }

  @media (min-width: $laptopXS) {
    padding: 4.8rem 7.8rem;
  }
}

.content {
  overflow: auto;

  padding: 0 1.7rem;

  @media (min-width: $tablet) {
    padding: 0 3.4rem;
  }

  @media (min-width: $laptopXS) {
    padding: 0 7.8rem;
  }
}
