@import '../../styles/effects';
@import '../../styles/spacing';

.container {
  padding: $padding;

  border-radius: 2rem;
  background: #fff;
  box-shadow: $baseBoxShadow;

  display: grid;
  place-items: center;
}

.info {
  margin-bottom: $margin;
}
