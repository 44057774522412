@import '../../styles/breakpoints';
@import '../../styles/effects';
@import '../../styles/spacing';

.content {
  width: auto;
  margin-bottom: $bottomPageMargin;

  text-align: left;

  background: #fff;

  // TODO remove when unused
  .invisible {
    display: none !important;
  }

  .pageHeader {
    padding: $padding 0;
  }

  .pageSubheader {
    margin-right: 0 !important;
  }

  .field {
    margin-bottom: $marginSmall;
  }

  .row {
    flex-wrap: wrap;

    width: 100%;

    display: flex;
    flex-direction: row;
  }

  .column {
    width: 100%;
  }

  .singleColumn {
    width: 100%;
  }

  .header {
    font-weight: 600;

    margin-top: 2.8rem;
  }

  .mobileOnly {
    display: none !important;
  }

  .submitButton {
    margin-top: 3.8rem !important;
  }

  @media (min-width: $tablet) {
    margin-bottom: 5rem;
    padding: 2rem 3rem 4.8rem 3rem;

    text-align: left;

    border-radius: 2rem;
    box-shadow: $baseBoxShadow;

    .column {
      display: flex;
      flex: 1;
      flex-direction: column;

      margin-right: 3.4rem;
    }

    .singleColumn {
      display: block;

      padding-right: 3.4rem;
    }
  }

  @media (min-width: $laptopXS) {
    .mobileOnly {
      display: block !important;
    }
  }

  @media (min-width: $laptopM) {
    padding: 4.8rem 7.8rem;

    .column {
      margin-right: 10rem;
    }

    .singleColumn {
      padding-right: 10rem;
    }
  }

  @media (min-width: $laptopXL) {
    width: $laptopM;
  }
}
