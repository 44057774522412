$width: 84px;
$height: 46px;

.inputGroup {
  display: flex !important;

  margin-bottom: 1rem !important;
}

.countryCode {
  width: $width !important;
  height: $height !important;
}

.countryCodeDropdown {
  width: $width !important;
}

.nationalNumber {
  width: calc(100% - #{$width});
  height: $height;

  text-align: left;

  border-radius: 0;
};
