$font: Montserrat, sans-serif;

$fontBase: 10px;
$fontMedium: 1.4rem;
$fontSmall: 1.2rem;

$lineHeight: 2.21;

$textColorLight: #fff;
$textColorDark: #354051;
$textColorSecondary: #949699;

:export {
  textColorDark: $textColorDark;
}
