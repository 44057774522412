@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/font';
@import '../../../../styles/effects';
@import '../../../../styles/sizes';
@import '../../../../styles/spacing';

.toast {
  font-size: $fontSmall;
  font-weight: 600;
  line-height: 1.17;

  letter-spacing: -.19px;

  color: #fff;

  span {
    width: 310px;
    min-height: 60px;
    padding: $padding;

    border-radius: 24px;
    box-shadow: $baseBoxShadow;

    display: grid;
    place-items: center;
  }

  .info {
    background-color: $infoColor;
  }

  .success {
    background-color: $successColor;
  }

  .error {
    background-color: $errorColor;
  }
}
