$primaryColor: #4155f0;
$primaryColorLight: #6d82fc;
$errorColor: #f25e5e;
$errorBgColor: rgba(242, 94, 94, .75);
$successColor: #48c980;
$infoColor: #354051;

:export {
  primaryColor: $primaryColor;
  errorColor: $errorColor;
  errorBgColor: $errorBgColor;
  successColor: $successColor;
}
