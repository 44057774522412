@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/font';

button.button {
  font-size: $fontSmall;
  line-height: 1.75;

  width: 14rem;
  height: 48px;

  letter-spacing: -.43px;

  @media (min-width: $tablet) {
    font-size: unset;

    width: 22rem;
    height: 6rem;

    letter-spacing: -.57px;
  }
}

.buttonSecondary {
  background: $primaryColorLight !important;

  @extend .button;
}

.link {
  width: auto !important;
  height: auto !important;

  &:focus {
    color: $primaryColor !important;
  }

  &:hover {
    color: $primaryColorLight !important;
  }
}
