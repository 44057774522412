.collapsibleForm-enter {
  height: 0;

  opacity: 0;
}

.collapsibleForm-enter-active {
  height: auto;

  transition: all 300ms;

  opacity: 1;
}

.collapsibleForm-exit {
  opacity: 1;
}

.collapsibleForm-exit-active {
  display: none;

  transition: all 3000ms;
}
