@import '../../styles/breakpoints';
@import '../../styles/spacing';

.content {
  width: auto;
  min-height: 12rem;
  margin-bottom: 10rem;

  text-align: left;

  border-radius: 2rem;
  background: #fff;

  .pageHeader {
    padding: 1.6rem 0;
  }

  .sectionContainer {
    margin-bottom: $margin;

    border-bottom: solid 1px #d0d0d0;

    .header {
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.94;

      letter-spacing: normal;
    }

    .subheader {
      font-size: 1.4rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;

      letter-spacing: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .flexbox {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .buttonLink {
    min-width: auto;
  }

  .bottomInfo {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;

    margin-top: 4rem;

    letter-spacing: normal;

    @media (min-width: $laptopXS) {
      width: 50%;
    }
  }

  @media (min-width: $tablet) {
    padding: 2rem 3rem;

    box-shadow: 0 5px 38px 0 rgba(25, 27, 61, .1);
  }

  @media (min-width: $laptopM) {
    padding: 4.8rem 7.8rem;
  }

  @media (min-width: $laptopXL) {
    width: $laptopM;
  }
}
