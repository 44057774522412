@import '../../styles/breakpoints';
@import '../../styles/spacing';

.inputsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  margin-bottom: $marginSmall;

  @media (min-width: $tablet) {
    flex-direction: row;
  }
}

.column {
  width: 100%;

  @media (min-width: $tablet) {
    flex: 1;

    margin-right: 10rem;
  }
}

.button {
  margin-bottom: $margin;
}
