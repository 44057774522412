@import '../../../../../styles/spacing';

.finishRegistration {
  font-size: 1.4rem;

  margin-bottom: $marginSmall;
}

.spaceBottomContainer {
  margin-bottom: $margin;
}

