@import '../../../../../styles/breakpoints';
@import '../../../../../styles/spacing';

.inputsContainer {
  width: 100%;
  margin-bottom: $margin;

  @media (min-width: $tablet) {
    width: 50%;
  }
}

.fullWidth {
  width: 100% !important;
}
