$tablet: 768px;
$laptopXS: 1024px;
$laptopS: 1280px;
$laptopM: 1440px;
$laptopL: 1920px;
$laptopXL: 2560px;

:export {
  tablet: $tablet;
  laptopXS: $laptopXS;
  laptopS: $laptopS;
  laptopM: $laptopM;
  laptopL: $laptopL;
  laptopXL: $laptopXL;
}
