.overlay {
  position: relative;
  z-index: 5;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  color: rgba(130, 130, 130, .5);

  display: grid;
  place-items: center;
}

.fullscreen {
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}
