.ReactModal__Overlay {
  transition: all 300ms ease-in-out;

  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
