@import '../../../../../styles/font';
@import '../../../../../styles/spacing';

.passwordContainer {
  margin-bottom: $marginSmall;
}

.registerButton {
  margin-top: $marginSmall;
}

.passwordRestrictions {
  font-size: 1.2rem;
  line-height: 1.92;

  color: $textColorSecondary;
}

.agreeTermsContainer {
  margin-bottom: $margin !important;
}

.agreeTerms {
  font-size: 1.2rem !important;
  line-height: 1.75 !important;

  margin-bottom: $marginSmall !important;
}
