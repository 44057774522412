$paddingSmall: 1rem;
$marginSmall: 1rem;

$bottomPageMargin: 8rem;

$padding: 2rem;
$margin: 2rem;

:export {
  marginSmall: $marginSmall;
}
