@import '../../../styles/breakpoints';
@import '../../../styles/font';

.modalContent {
  font-family: $font;

  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  transform: translate(-50%, -50%);

  color: $textColorDark;
  border-radius: 2rem;
  outline: none;
  background: #fff;
}

.modalOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, .5);
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;

  margin: 2.4rem 1.7rem;

  cursor: pointer;

  @media (min-width: $tablet) {
    margin: 2.4rem;
  }
}
