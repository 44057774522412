@import '../../../../../styles/spacing';

.fullWidthFormContainer {
  width: 100%;
  margin-bottom: $marginSmall;
}

.loginButtonsContainer {
  margin-bottom: $margin;
}
