@import '../../../../../styles/spacing';

.form {
  margin-bottom: 4.8rem;

  display: flex;
  flex-direction: column;

  .fullWidthFormContainer {
    width: 100%;
    margin-bottom: $marginSmall;
  }
}

.phoneNumberLabel {
  font-size: 1.6rem;
  line-height: 2rem;

  margin-bottom: 1rem;
}
